var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('auto-table',{attrs:{"DataList":_vm.DataList,"option":_vm.Option,"total":_vm.Total},on:{"changePage":_vm.getList},scopedSlots:_vm._u([{key:"category",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('p',[_vm._v(" "+_vm._s(row.apply.category)+_vm._s(row.apply.course_num)+"节 "),_c('span',{style:({color:_vm.typeMap[row.apply.type].color || ''})},[_vm._v(_vm._s(_vm.typeMap[row.apply.type].name))])])])]}},{key:"name",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('p',[_vm._v(_vm._s(row.apply.name))]),_c('p',[_vm._v(_vm._s(row.apply.phone))])])]}},{key:"addr",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('p',[_vm._v(_vm._s(row.apply.mechanism_name))]),_c('p',[_vm._v(_vm._s(row.apply.address))])])]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('el-tag',{attrs:{"hit":false,"size":"small","effect":"plain","type":_vm.statusMap[row.apply.status].tag}},[_vm._v(_vm._s(_vm.statusMap[row.apply.status].name))])],1)]}},{key:"handler",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.details(row)}}},[_vm._v("处理任务")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }